<template>
    <PopupDialog
        :show="show"
        :hideScroll="false"
        :hasButtons="false"
        :closeable="true"
        :zIndex="zIndex"
        maxWidth="sm"
        @closeModal="closeModal">
        <template #bodyContainer>
            <div class="relative flex w-full flex-col bg-[#111111] text-white">
                <div class="relative">
                    <div
                        class="h-[121px] w-full bg-cover bg-center bg-no-repeat"
                        :style="`background-image: url(${props.creator.subscription.cover_image})`"></div>
                    <img
                        class="hidden h-[121px] w-full"
                        :src="props.creator.subscription.cover_image" />
                    <div class="absolute left-[20px] top-[22px] flex">
                        <div
                            v-if="!props.creator.avatar"
                            class="mx-auto mb-[5px] h-[80px] w-[80px] rounded-full">
                            <font-awesome-icon
                                icon="fa-solid fa-user-circle"
                                class="h-[80px] w-[80px] rounded-full bg-gray-300 text-neutral-700" />
                        </div>
                        <img
                            v-else
                            :src="props.creator.avatar"
                            alt="avatar"
                            class="mx-auto h-[80px] w-[80px] rounded-full" />
                        <div class="flex h-[80px] items-center justify-center">
                            <div class="flex flex-col pl-[11px] text-left">
                                <p
                                    class="mb-[5px] block truncate text-[18px] font-semibold capitalize leading-snug text-white">
                                    {{ props.creator.name }}
                                </p>
                                <p
                                    class="text-minor-dark text-secondary relative block text-[14px] text-white">
                                    @{{ props.creator.profile_url }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <font-awesome-icon
                        @click="closeModal"
                        class="absolute right-5 top-5 h-[27px] w-[27px] text-white md:hover:cursor-pointer"
                        :icon="['fas', 'xmark']" />
                </div>
                <div
                    class="mb-[16px] pt-[25px] text-center text-[16px] font-medium leading-[18px] tracking-[.54px] text-white">
                    Subscriber benefits include:
                </div>

                <div class="mx-auto my-[14px] w-auto">
                    <ul class="mb-[18px] flex w-full flex-col items-start text-center">
                        <li
                            v-for="benefit in benefits"
                            class="my-[8px] flex items-center justify-center whitespace-nowrap text-left text-[16px] font-normal leading-normal">
                            <font-awesome-icon
                                icon="fa-regular fa-check"
                                class="mr-[8px] h-[18px] w-[18px] text-mintgreen" />
                            {{ benefit }}
                        </li>
                    </ul>
                </div>

                <div class="mb-4 flex w-auto flex-col items-center justify-center px-2">
                    <div class="my-[12px] flex w-auto flex-col items-center justify-center px-2">
                        <p v-if="insufficientFunds" class="mb-10 text-red-900">
                            Not enough funds in wallet, you can add more
                            <Link :href="route('profile.wallet')" class="font-bold">here</Link>
                            .
                        </p>
                        <p v-else-if="subscriptionExists" class="mb-10 text-red-900">
                            Subscription exists.
                        </p>
                        <p v-else-if="subscriptionIsPaid" class="mb-10 text-red-900">
                            Subscription is paid
                        </p>
                        <div v-else-if="errorMessage" class="mb-5 rounded-lg bg-pink-100 p-2">
                            <p class="text-red-600">{{ errorMessage }}</p>
                        </div>
                        <font-awesome-icon :icon="['fas', 'spinner']" spin v-if="loading" />

                        <a
                            v-else-if="!props.creator.subscription.free_is_active"
                            @click.prevent="suscribeForm"
                            class="mx-auto block rounded-[6px] bg-mintgreen px-[16px] py-[15px] text-[16px] font-medium uppercase tracking-[.48px] text-black hover:bg-hovermintgreen">
                            {{ subscribingText }} - {{ props.creator.subscription.amount }}/mo
                        </a>
                        <AppButton v-else @click="subscribe" :text="freeText" ui="primary" />
                    </div>
                </div>
            </div>
        </template>
    </PopupDialog>
</template>

<script setup>
    import { ref, computed } from 'vue'
    import { Link, router } from '@inertiajs/vue3'
    import AppButton from '@/Components/ui-components/AppButton.vue'
    import PopupDialog from '@/Components/ui-components/PopupDialog.vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

    const props = defineProps({
        creator: { type: Object, default: {} },
        authUserHasCardOnFile: { type: Boolean, default: false },
        float_user_id: { type: Number, default: false },
        user: { type: Object, default: {} },
        show: {
            type: Boolean,
            default: false,
        },
        hasSubscriptionVideos: {
            type: Boolean,
            default: false,
        },
        zIndex: {
            type: String,
            default: 'z-50',
        },
        live_show_id: {
            type: Number,
            default: 0,
        },
    })
    //console.log(props)
    const benefits = computed(() => {
        let benefitsList = []
        const allow_subscription = props.creator?.subscription?.allow_subscription
        const subscription_allow_video_content =
            props.creator?.subscription?.subscription_allow_video_content

        if (allow_subscription && subscription_allow_video_content) {
            benefitsList.unshift("Access to Creator's exclusive content")
        }
        if (props.creator?.subscription?.subscription_allow_dm) {
            benefitsList.push('Direct messaging with Creator')
        }
        benefitsList.push('Cancel Anytime!')

        return benefitsList
    })
    const emit = defineEmits(['onSubscribe', 'closeModal'])

    function closeModal() {
        emit('closeModal')
    }

    const isPayWithWallet = ref(false)
    const loading = ref(false)
    const errorMessage = ref('')
    const insufficientFunds = ref(false)
    const subscriptionExists = ref(false)
    const subscriptionIsPaid = ref(false)
    const isOwn = ref(props.creator.id === props.user.id)

    function suscribeForm() {
        submitSubscription()
    }

    function submitSubscription() {
        axios
            .post(route('billing.subscribe', props.creator), {
                isPayWithWallet: isPayWithWallet.value ? 1 : 0,
            })
            .then(response => {
                if (response.data.error == 'NeedAdditionalFundsException') {
                    insufficientFunds.value = true
                } else if (response.data.error == 'SubscriptionExistsException') {
                    subscriptionExists.value = true
                } else if (response.data.error == 'SubscriptionIsPaidException') {
                    if (props.float_user_id && props.creator.subscription.float_signup_url) {
                        window.location.href =
                            props.creator.subscription.float_signup_url +
                            (props.live_show_id > 0 ? '&live_show_id=' + props.live_show_id : '')
                    } else {
                        window.location.href =
                            props.creator.subscription.signup_url +
                            (props.live_show_id > 0 ? '&live_show_id=' + props.live_show_id : '')
                    }
                    return
                } else if (response.data.error) {
                    errorMessage.value = response.data.error
                } else {
                    emit('onSubscribe', response.data)
                }
            })
            .catch(function (error) {
                if (error.response) {
                    errorMessage.value = error.response.data.message
                }
            })
            .finally(() => (loading.value = false))
    }

    function subscribe() {
        if (!props.creator.subscription.free_is_active || props.authUserHasCardOnFile === false) {
            if (props.float_user_id && props.creator.subscription.float_signup_url) {
                window.location.href =
                    props.creator.subscription.float_signup_url +
                    (props.live_show_id > 0 ? '&live_show_id=' + props.live_show_id : '')
            } else {
                window.location.href =
                    props.creator.subscription.signup_url +
                    (props.live_show_id > 0 ? '&live_show_id=' + props.live_show_id : '')
            }
        } else {
            loading.value = true
            errorMessage.value = ''
            insufficientFunds.value = false
            subscriptionExists.value = false
            subscriptionIsPaid.value = false
            submitSubscription()
        }
    }
    const subscribingText = computed(() => {
        if (!props.hasSubscriptionVideos) {
            return 'SUBSCRIBE'
        } else {
            return 'SUBSCRIBE'
        }
    })
    const freeText = computed(() => {
        if (!props.hasSubscriptionVideos) {
            return 'FREE'
        } else {
            return 'FREE'
        }
    })
</script>

<style scoped>
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
</style>
